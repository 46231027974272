





import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  props: ['isLoading', 'noDelay'],
})
export default class LoadingBar extends Vue {
  isDelayed = false;
  @Watch('$props.isLoading')
  delayLoader() {
    if (this.$props.isLoading) {
      this.isDelayed = !this.$props.noDelay;
      setTimeout(() => (this.isDelayed = false), 500);
    }
  }
  mounted() {
    if (this.$props.isLoading) {
      this.isDelayed = !this.$props.noDelay;
      setTimeout(() => (this.isDelayed = false), 500);
    }
  }
}
