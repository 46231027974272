import { httpService } from '@/services/HttpService';

class SpreadsheetService {
  get(id: any): Promise<any> {
    return httpService.get(`spreadsheets/${id}`, {}, {});
  }
  save(id: any, data: any): Promise<any> {
    return httpService.post(`spreadsheets/${id}`, data, {});
  }
}

export const spreadsheetService = new SpreadsheetService();
