import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import jexcel from 'jexcel';

class XlsxService {
  generate(
    data: any,
    options: {
      merges: any;
      styles: any;
      moneyCells: any;
      columnWidth: any;
      rowHeight: any;
    },
  ) {
    const zip = new JSZip();
    zip.file('[Content_Types].xml', XlsxService.getContentType());

    const rels: any = zip.folder('_rels');
    rels.file('.rels', XlsxService.getRels());

    const docProps: any = zip.folder('docProps');
    docProps.file('app.xml', XlsxService.getDocPropsApp());
    docProps.file('core.xml', XlsxService.getDocPropsCore());

    const sheet = XlsxService.getSheet(data, options);

    const xl: any = zip.folder('xl');
    xl.file('styles.xml', XlsxService.getStyles(sheet.styles));
    xl.file('workbook.xml', XlsxService.getWorkbook());
    xl.file('sharedStrings.xml', XlsxService.getSharedStrings(sheet.strings));

    const xlRels: any = xl.folder('_rels');
    xlRels.file('workbook.xml.rels', XlsxService.getXlRels());

    const worksheets: any = xl.folder('worksheets');
    worksheets.file('sheet1.xml', sheet.data);

    zip.generateAsync({ type: 'blob' }).then(function(content) {
      saveAs(content, 'test.xlsx');
    });
  }

  private static getRels() {
    return `<?xml version="1.0" encoding="UTF-8"?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
<Relationship Id="rId2" Type="http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties" Target="docProps/core.xml"/>
<Relationship Id="rId3" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/extended-properties" Target="docProps/app.xml"/>
<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="xl/workbook.xml"/>
</Relationships>`;
  }

  private static getDocPropsApp() {
    return `<?xml version="1.0" encoding="utf-8"?>
<Properties xmlns:vt="http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes" xmlns="http://schemas.openxmlformats.org/officeDocument/2006/extended-properties" />`;
  }

  private static getDocPropsCore() {
    return `<?xml version="1.0" encoding="utf-8"?>
<cp:coreProperties xmlns:dcterms="http://purl.org/dc/terms/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:dcmitype="http://purl.org/dc/dcmitype/" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cp="http://schemas.openxmlformats.org/package/2006/metadata/core-properties" />`;
  }

  private static getXlRels() {
    return `<?xml version="1.0" encoding="UTF-8"?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet" Target="worksheets/sheet1.xml"/>
<Relationship Id="rId2" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles" Target="styles.xml"/>
<Relationship Id="rId3" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/sharedStrings" Target="sharedStrings.xml" />
</Relationships>`;
  }

  private static getContentType() {
    return `<?xml version="1.0" encoding="UTF-8"?>
<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">
<Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml"/>
<Default Extension="xml" ContentType="application/xml"/>
<Override PartName="/xl/workbook.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"/>
<Override PartName="/xl/worksheets/sheet1.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml"/>
<Override PartName="/xl/styles.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml"/>
<Override PartName="/docProps/core.xml" ContentType="application/vnd.openxmlformats-package.core-properties+xml"/>
<Override PartName="/docProps/app.xml" ContentType="application/vnd.openxmlformats-officedocument.extended-properties+xml"/>
<Override PartName="/xl/sharedStrings.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml" />
</Types>`;
  }

  private static getWorkbook() {
    return `<?xml version="1.0" encoding="UTF-8"?>
<workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">
<sheets><sheet name="Arkusz 1" sheetId="1" r:id="rId1"/></sheets>
</workbook>`;
  }

  private static getSharedStrings(stringData: any) {
    const strings: any = [];
    stringData.map((str: string) => {
      strings.push(`<si><t>${str}</t></si>`);
    });
    return `<?xml version="1.0" encoding="utf-8"?>
<sst count="${strings.length}" uniqueCount="${
      strings.length
    }" xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main">
${strings.join('')}
</sst>`;
  }

  private static getSheet(
    data: any,
    options: {
      merges: any;
      styles: any;
      moneyCells: any;
      columnWidth: any;
      rowHeight: any;
    },
  ) {
    let columns = '';
    options.columnWidth.map((width: any, index: number) => {
      columns += `<col min="${index + 1}" max="${index + 1}" width="${Math.round((width ? width : 120) * 0.15)}"/>`;
    });

    let merges = '';
    Object.keys(options.merges).map((startCell: string) => {
      const startCellId = jexcel.getIdFromColumnName(startCell, true);
      const endCell = jexcel.getColumnNameFromId([
        startCellId[0] + options.merges[startCell][0] - 1,
        startCellId[1] + options.merges[startCell][1] - 1,
      ]);
      merges += `<mergeCell ref="${startCell}:${endCell}"/>`;
    });
    if (merges !== '') {
      merges = `<mergeCells>${merges}</mergeCells>`;
    }

    let rowXml = '';
    let fonts: any = [];
    let fills: any = [];
    let xfs: any = [];
    const strings: any = [];
    data.map((row: any, rowIndex: number) => {
      let rowXmlTmp = '';
      row.map((col: any, colIndex: number) => {
        const cellName = jexcel.getColumnNameFromId([colIndex, rowIndex]);
        if (col === '' || col === null) {
          return;
        }

        let parsed: any;
        if (!isNaN(parseFloat(col))) {
          if (options.moneyCells[cellName]) {
            parsed = XlsxService.cssToXml(options.styles[cellName] || '', fonts, fills, xfs, true, true);
            rowXmlTmp += `<c r="${cellName}" t="n" s="${parsed.xfId}"><v>${col ? parseFloat(col) : '0'}</v></c>`;
          } else if (/^-*[0-9,]+$/.test(col)) {
            parsed = XlsxService.cssToXml(options.styles[cellName] || '', fonts, fills, xfs, true, false);
            rowXmlTmp += `<c r="${cellName}" t="n" s="${parsed.xfId}"><v>${col ? parseFloat(col) : '0'}</v></c>`;
          } else {
            parsed = XlsxService.cssToXml(options.styles[cellName] || '', fonts, fills, xfs, false, false);
            const valFixed = col ? col.toString().replace(/[\000-\031]+/g, '') : '';
            rowXmlTmp += `<c r="${cellName}" s="${parsed.xfId}" t="s"><v>${strings.length}</v></c>`;
            strings.push(valFixed);
          }
        } else {
          parsed = XlsxService.cssToXml(options.styles[cellName] || '', fonts, fills, xfs, false, false);
          const valFixed = col ? col.replace(/[\000-\031]+/g, '') : '';
          rowXmlTmp += `<c r="${cellName}" s="${parsed.xfId}" t="s"><v>${strings.length}</v></c>`;
          strings.push(valFixed);
        }
        fonts = parsed.fonts;
        fills = parsed.fills;
        xfs = parsed.xfs;
      });
      if (rowXmlTmp !== '') {
        rowXml += `<row r="${rowIndex + 1}" ht="${options.rowHeight[rowIndex] ? options.rowHeight[rowIndex] : 20}">`;
        rowXml += rowXmlTmp;
        rowXml += '</row>';
      } else {
        rowXml += `<row r="${rowIndex + 1}" ht="${options.rowHeight[rowIndex] ? options.rowHeight[rowIndex] : 20}" />`;
      }
    });

    return {
      data: `<?xml version="1.0" encoding="UTF-8"?>
<worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">
    <cols>
        ${columns}
    </cols>
    <sheetData>
        ${rowXml}
    </sheetData>
    ${merges}
</worksheet>`,
      styles: {
        fonts,
        fills,
        xfs,
      },
      strings: strings,
    };
  }

  private static cssToXml(style: string, fonts: any, fills: any, xfs: any, isNumber: boolean, isMoney: boolean) {
    let font = `<font>`;
    if (style.toLowerCase().indexOf('bold') !== -1) {
      font += `<b val="true"/>`;
    }
    if (style.toLowerCase().indexOf('italic') !== -1) {
      font += `<i val="true"/>`;
    }
    if (style.toLowerCase().indexOf('font-size') !== -1) {
      const size = style.match(/font-size:\s*(\d+)px/);
      if (size) {
        font += `<sz val="${size[1]}"/>`;
      } else {
        font += `<sz val="12"/>`;
      }
    } else {
      font += `<sz val="12"/>`;
    }
    if (style.toLowerCase().indexOf('color:') !== -1) {
      const color = style.match(/(^|[^-])color:\s*([^;]+)/);
      if (color) {
        if (color[2].indexOf('rgb') !== -1) {
          const rgb = color[2].match(/rgb[a]{0,1}\((\d+)[^0-9]*(\d+)[^0-9]*(\d+)/);
          if (rgb) {
            let hex1 = parseInt(rgb[1])
              .toString(16)
              .toUpperCase();
            let hex2 = parseInt(rgb[2])
              .toString(16)
              .toUpperCase();
            let hex3 = parseInt(rgb[3])
              .toString(16)
              .toUpperCase();
            hex1 = hex1.length !== 2 ? `0${hex1}` : hex1;
            hex2 = hex2.length !== 2 ? `0${hex2}` : hex2;
            hex3 = hex3.length !== 2 ? `0${hex3}` : hex3;
            font += `<color rgb="FF${hex1}${hex2}${hex3}"/>`;
          }
        } else {
          font += `<color rgb="FF${color[2].toUpperCase()}"/>`;
        }
      }
    } else {
      font += `<color rgb="FF000000"/>`;
    }
    font += `<name val="Calibri"/></font>`;

    let fill = '';
    if (style.toLowerCase().indexOf('background') !== -1) {
      const background = style.match(/background[^:]*:\s*([^;]+)/);
      if (background) {
        if (background[1].indexOf('rgb') !== -1) {
          const rgb = background[1].match(/rgb[a]{0,1}\((\d+)[^0-9]*(\d+)[^0-9]*(\d+)/);
          if (rgb) {
            let hex1 = parseInt(rgb[1])
              .toString(16)
              .toUpperCase();
            let hex2 = parseInt(rgb[2])
              .toString(16)
              .toUpperCase();
            let hex3 = parseInt(rgb[3])
              .toString(16)
              .toUpperCase();
            hex1 = hex1.length !== 2 ? `0${hex1}` : hex1;
            hex2 = hex2.length !== 2 ? `0${hex2}` : hex2;
            hex3 = hex3.length !== 2 ? `0${hex3}` : hex3;
            fill = `<fill><patternFill patternType="solid"><fgColor rgb="FF${hex1}${hex2}${hex3}"/><bgColor rgb="FF${hex1}${hex2}${hex3}"/></patternFill></fill>`;
          }
        } else {
          fill = `<fill><patternFill patternType="solid"><fgColor rgb="FF${background[1].toUpperCase()}"/><bgColor rgb="FF${background[1].toUpperCase()}"/></patternFill></fill>`;
        }
      }
    }

    let fontId = 0;
    if (fonts.indexOf(font) !== -1) {
      fontId = fonts.indexOf(font) + 1;
    } else {
      fonts.push(font);
      fontId = fonts.length;
    }

    let fillId = 0;
    if (fill !== '') {
      if (fills.indexOf(fill) !== -1) {
        fillId = fills.indexOf(fill) + 2;
      } else {
        fills.push(fill);
        fillId = fills.length + 1;
      }
    }

    let align = 'center';
    if (style.toLowerCase().indexOf('text-align') !== -1) {
      const alignTmp = style.match(/text-align:\s*([^;]+|$)/);
      if (alignTmp && ['left', 'center', 'right'].indexOf(alignTmp[1].toLowerCase()) !== -1) {
        align = alignTmp[1].toLowerCase();
      }
    }

    const xf = `<xf numFmtId="${
      isNumber ? (isMoney ? 165 : 164) : '164'
    }" fontId="${fontId}" fillId="${fillId}" applyFont="1" applyFill="1" borderId="0" ${
      isNumber ? 'applyNumberFormat="1"' : ''
    } applyAlignment="true"><alignment horizontal="${align}" vertical="center" wrapText="true" /></xf>`;

    let xfId = 0;
    if (xfs.indexOf(xf) !== -1) {
      xfId = xfs.indexOf(xf) + 1;
    } else {
      xfs.push(xf);
      xfId = xfs.length;
    }

    return {
      fonts: fonts,
      fills: fills,
      xfs: xfs,
      xfId: xfId,
    };
  }

  private static getStyles(styles: any) {
    return `<?xml version="1.0" encoding="UTF-8"?>
<styleSheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main">
    <numFmts count="2">
        <numFmt numFmtId="164" formatCode="General"/>
        <numFmt numFmtId="165" formatCode="#,##0.00\\ [$zł-415];[RED]\\-#,##0.00\\ [$zł-415]"/>
    </numFmts>
    <fonts count="${styles.fonts.length + 1}">
        <font>
           <sz val="12" />
           <color rgb="ff000000" />
           <name val="Calibri" />
        </font>
        ${styles.fonts.join('')}
    </fonts>
    <fills count="${styles.fills.length + 2}">
        <fill>
            <patternFill patternType="none"/>
        </fill>
        <fill>
            <patternFill patternType="gray125"/>
        </fill>
        ${styles.fills.join('')}
    </fills>
    <borders count="1">
        <border>
            <left/>
            <right/>
            <top/>
            <bottom/>
            <diagonal/>
        </border>
    </borders>
    <cellStyleXfs count="1">
        <xf numFmtId="0" fontId="0" fillId="0" borderId="0"/>
    </cellStyleXfs>
    <cellXfs count="${styles.xfs.length + 1}">
        <xf numFmtId="0" fontId="0" fillId="0" borderId="0" xfId="0" />
        ${styles.xfs.join('')}
    </cellXfs>
    <cellStyles count="1">
        <cellStyle name="Normal" xfId="0" builtinId="0" />
    </cellStyles>
    <colors />
</styleSheet>`;
  }
}

export const xlsxService = new XlsxService();
