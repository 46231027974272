import { render, staticRenderFns } from "./SpreadsheetEditor.vue?vue&type=template&id=9c23944c&scoped=true&"
import script from "./SpreadsheetEditor.vue?vue&type=script&lang=ts&"
export * from "./SpreadsheetEditor.vue?vue&type=script&lang=ts&"
import style0 from "./SpreadsheetEditor.vue?vue&type=style&index=0&id=9c23944c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c23944c",
  null
  
)

export default component.exports