
















import { Component, Vue, Watch } from 'vue-property-decorator';
import { Compact } from 'vue-color';

@Component({
  components: { 'compact-picker': Compact },
  props: ['isBackground'],
})
export default class ColorPicker extends Vue {
  color: any = { hex: '#000000' };
  isOpened = false;
  palette = [
    '#4D4D4D',
    '#999999',
    '#777777',
    '#F44E3B',
    '#FE9200',
    '#FCDC00',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#73D8FF',
    '#AEA1FF',
    '#FDA1FF',
    '#333333',
    '#808080',
    '#CCCCCC',
    '#D33115',
    '#E27300',
    '#FCC400',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#009CE0',
    '#7B64FF',
    '#FA28FF',
    '#000000',
    '#666666',
    '#B3B3B3',
    '#9F0500',
    '#C45100',
    '#FB9E00',
    '#808900',
    '#194D33',
    '#0C797D',
    '#0062B1',
    '#653294',
    '#AB149E',
    // custom
    '#FFFFFF',
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#00FFFF',
    '#C0C0C0',
    '#9999FF',
    '#FFFFCC',
    '#CCFFFF',
    '#FF8080',
    '#CCFFCC',
    '#FFFF99',
    '#99CCFF',
    '#FF99CC',
    '#CC99FF',
    '#FFCC99',
    '#FEF5A8',
    '#C5F19A',
    '#FFD8A0',
    '#F5DDB7',
    '#B9D0E8',
    '#D6BFD4',
    '#F79494',
    '#D3D7CF',
  ];

  @Watch('color')
  colorSelected() {
    if (this.$props.isBackground) {
      this.setBackground();
    } else {
      this.setColor();
    }
    return this.color.hex;
  }

  setColor() {
    this.$emit('color-selected', this.color.hex);
  }
  setBackground() {
    this.$emit('background-selected', this.color.hex);
  }

  toggleColorPicker() {
    this.isOpened = !this.isOpened;
  }
}
