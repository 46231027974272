













































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import jexcel from 'jspreadsheet-ce';
import ColorPicker from '@/components/common/ColorPicker.vue';
import EventBus from '@/EventBus';
import { httpService } from '@/services/HttpService';
import { spreadsheetService } from '@/services/SpreadsheetService';
import LoadingBar from '@/components/common/LoadingBar.vue';
import { xlsxService } from '@/services/XlsxService';
import { customQueryTesterService } from '@/services/CustomQueryTesterService';

@Component({
  components: { LoadingBar, Header, ColorPicker },
})
export default class SpreadsheetEditor extends Vue {
  data: any = [];
  jExcelOptions: any = {};
  jExcelObj: any = {};
  lastSelectedCells: any = [];
  lastSelectedWidth = 0;
  lastSelectedHeight = 0;
  firstSelectedCell = '';
  isFontSizeOpen = false;
  spreadSheetExternalData: any = {};
  isLoading = true;
  cellsWidth: any = [];
  cellsHeight: any = [];
  moneyCells: any = {};
  cellsToUpdate: any = {};
  dataToFetch: any = {};
  isFetchingData = false;
  isCellValueInInput = false;
  lastCords = { x1: 0, x2: 0, y1: 0, y2: 0 };
  currentCellValue = '';
  needMultipleCellsUpdate = false;
  knownMethods: any = {};

  accountUpdater() {
    if (this.isFetchingData) {
      return;
    }
    const keysToFetch = Object.keys(this.dataToFetch);
    if (keysToFetch && keysToFetch[0]) {
      const obj = this.dataToFetch[keysToFetch[0]];
      const method = keysToFetch[0].split('|')[0];
      this.isFetchingData = true;

      let apiMethod = 'accountLikeSum';
      if (method === 'ac') {
        apiMethod = 'accountLikeContainedSum';
      }
      if (method === 'b') {
        apiMethod = 'balanceLikeSum';
      }

      if (method === 'c') {
        new Promise(resolve => {
          if (this.knownMethods[obj.name]) {
            resolve(this.knownMethods[obj.name]);
          } else {
            customQueryTesterService
              .findQuery(obj.name)
              .then((data: any) => {
                this.knownMethods[obj.name] = data;
                resolve(data);
              })
              .catch(() => {
                this.spreadSheetExternalData[keysToFetch[0]] = '#ERROR';
                if (this.dataToFetch[keysToFetch[0]]) {
                  delete this.dataToFetch[keysToFetch[0]];
                  this.dataToFetch = { ...this.dataToFetch };
                }
                this.updateExternalCells();
                this.isFetchingData = false;
                this.accountUpdater();
              });
          }
        }).then((data: any) => {
          const reqParams: any = {};

          let index = 0;
          if (data.type !== 'sum' && obj.params && obj.params.length > 0) {
            index = obj.params[0];
            obj.params.splice(0, 1);
          }
          data.params.split(',').map((param: any, index: any) => {
            if (param.trim()) {
              reqParams[param.trim()] = obj.params[index] ? obj.params[index] : '';
            }
          });
          customQueryTesterService
            .test(data.id, reqParams)
            .then(apiData => {
              if (data.type === 'sum') {
                this.spreadSheetExternalData[keysToFetch[0]] = apiData.result;
              } else {
                if (apiData.result && apiData.result[index] && apiData.result[index].value) {
                  this.spreadSheetExternalData[keysToFetch[0]] = apiData.result[index].value;
                } else {
                  this.spreadSheetExternalData[keysToFetch[0]] = '';
                }
              }

              if (this.dataToFetch[keysToFetch[0]]) {
                delete this.dataToFetch[keysToFetch[0]];
                this.dataToFetch = { ...this.dataToFetch };
              }
              this.updateExternalCells();
              this.isFetchingData = false;
              this.accountUpdater();
            })
            .catch(() => {
              this.spreadSheetExternalData[keysToFetch[0]] = '#ERROR';
              if (this.dataToFetch[keysToFetch[0]]) {
                delete this.dataToFetch[keysToFetch[0]];
                this.dataToFetch = { ...this.dataToFetch };
              }
              this.updateExternalCells();
              this.isFetchingData = false;
              this.accountUpdater();
            });
        });
        return;
      }

      httpService
        .post(`connectorQuickFetch/${apiMethod}`, obj)
        .then(data => {
          this.spreadSheetExternalData[keysToFetch[0]] = data.result;
          if (this.dataToFetch[keysToFetch[0]]) {
            delete this.dataToFetch[keysToFetch[0]];
            this.dataToFetch = { ...this.dataToFetch };
          }
          this.updateExternalCells();
          this.isFetchingData = false;
          this.accountUpdater();
        })
        .catch(() => {
          this.spreadSheetExternalData[keysToFetch[0]] = '#ERROR';
          if (this.dataToFetch[keysToFetch[0]]) {
            delete this.dataToFetch[keysToFetch[0]];
            this.dataToFetch = { ...this.dataToFetch };
          }
          this.updateExternalCells();
          this.isFetchingData = false;
          this.accountUpdater();
        });
    }
  }

  setValueByInput() {
    this.jExcelObj.setValue(this.firstSelectedCell, this.currentCellValue);
  }

  mounted() {
    (window as any).PRECISION = (number: any) => {
      return parseFloat(parseFloat(number).toPrecision(12));
    };

    const accountIn = (acc: string, verify: string, date: string, type: string, version = '') => {
      const dataId = `ac|${acc}|${verify}|${date}|${type}|${version}`;
      if (this.spreadSheetExternalData[dataId] === undefined) {
        this.dataToFetch[dataId] = { code: acc, verify: verify, month: date, type: type };
        this.accountUpdater();
      }
      return this.spreadSheetExternalData[dataId] === undefined ? '[pobieram]' : this.spreadSheetExternalData[dataId];
    };

    (window as any).ACCOUNTIN = accountIn;
    (window as any).KONTOW = accountIn;

    const account = (acc: string, date: string, type: string, version = '') => {
      const dataId = `a|${acc}|${date}|${type}|${version}`;
      if (this.spreadSheetExternalData[dataId] === undefined) {
        this.dataToFetch[dataId] = { code: acc, month: date, type: type };
        this.accountUpdater();
      }
      return this.spreadSheetExternalData[dataId] === undefined ? '[pobieram]' : this.spreadSheetExternalData[dataId];
    };

    (window as any).ACCOUNT = account;
    (window as any).KONTO = account;

    const balance = (acc: string, date: string, type: string, version = '') => {
      const dataId = `b|${acc}|${date}|${type}|${version}`;
      if (this.spreadSheetExternalData[dataId] === undefined) {
        this.dataToFetch[dataId] = { code: acc, month: date, type: type };
        this.accountUpdater();
      }
      return this.spreadSheetExternalData[dataId] === undefined ? '[pobieram]' : this.spreadSheetExternalData[dataId];
    };

    (window as any).BALANCE = balance;
    (window as any).SALDO = balance;

    const customQuery = (methodName: string, ...args: any) => {
      let dataId = `c|${methodName}`;
      args.map((val: any) => {
        dataId += '|' + val;
      });
      if (this.spreadSheetExternalData[dataId] === undefined) {
        this.dataToFetch[dataId] = { name: methodName, params: args };
        this.accountUpdater();
      }
      return this.spreadSheetExternalData[dataId] === undefined ? '[pobieram]' : this.spreadSheetExternalData[dataId];
    };

    (window as any).FN = customQuery;
    (window as any).CUSTOM = customQuery;
    (window as any).ZAPYTANIE = customQuery;

    EventBus.$on('document-clicked', () => {
      this.isFontSizeOpen = false;
    });

    this.jExcelOptions = {
      data: [[]],
      minDimensions: [60, 100],
      defaultColWidth: 120,
      rowResize: true,
      wordWrap: true,
      tableOverflow: true,
      tableWidth: 'calc(100vw - 2px)',
      tableHeight: 'calc(100vh - 98px)',
      onblur: () => {
        this.jExcelObj.updateSelectionFromCoords(
          this.lastCords.x1,
          this.lastCords.y1,
          this.lastCords.x2,
          this.lastCords.y2,
        );
      },
      onselection: (instance: any, x1: any, y1: any, x2: any, y2: any) => {
        const cells = [];
        this.lastCords = { x1, y1, x2, y2 };
        for (let x = x1; x <= x2; x++) {
          for (let y = y1; y <= y2; y++) {
            const cellName = jexcel.getColumnNameFromId([x, y]);
            cells.push(cellName);
          }
        }
        this.firstSelectedCell = jexcel.getColumnNameFromId([x1, y1]);
        if (this.jExcelObj && this.jExcelObj.getValue) {
          this.currentCellValue = this.jExcelObj.getValue(this.firstSelectedCell);
          this.isCellValueInInput = true;
        }
        this.lastSelectedWidth = x2 - x1 + 1;
        this.lastSelectedHeight = y2 - y1 + 1;
        this.lastSelectedCells = cells;
      },
      onresizecolumn: (instance: any, cell: any, width: any) => {
        this.cellsWidth[cell] = width;
      },
      onresizerow: (instance: any, cell: any, height: any) => {
        this.cellsHeight[cell] = height;
      },
      onafterchanges: (el: any, cells: any) => {
        cells.map((cell: any) => {
          const cellName = jexcel.getColumnNameFromId([cell.x, cell.y]);
          if (cell.newValue[0] === '=') {
            this.cellsToUpdate[cellName] = 1;
          }
          if (this.moneyCells[cellName]) {
            const newEl = el.querySelector('[data-y="' + cell.y + '"] [data-x="' + cell.x + '"]');
            if (cell.newValue[0] === '=') {
              const value = this.jExcelObj.executeFormula(cell.newValue, cell.x, cell.y);
              if (!isNaN(parseFloat(value))) {
                newEl.innerHTML = this.moneyFormat(value);
              }
            } else if (!isNaN(parseFloat(cell.newValue))) {
              newEl.innerHTML = this.moneyFormat(cell.newValue);
            }
          }
        });
      },
      text: {
        noRecordsFound: 'Nie znaleziono rekordów',
        showingPage: 'Strona {0} z {1}',
        show: 'Pokaż',
        entries: 'wpisy',
        insertANewColumnBefore: 'Wstaw nową kolumnę przed',
        insertANewColumnAfter: 'Wstaw nową kolumnę po',
        deleteSelectedColumns: 'Usuń wybrane kolumny',
        renameThisColumn: 'Zmień nazwę tej kolumny',
        orderAscending: 'Rosnąco',
        orderDescending: 'Malejąco',
        insertANewRowBefore: 'Wstaw nowy wiersz przed',
        insertANewRowAfter: 'Wstaw nowy wiersz po',
        deleteSelectedRows: 'Usuń wybrane wiersze',
        editComments: 'Edytuj komentarze',
        addComments: 'Dodaj komentarze',
        comments: 'Komentarze',
        clearComments: 'Wyczyść komentarze',
        copy: 'Kopiuj ...',
        paste: 'Wklej ...',
        saveAs: 'Zapisz jako ...',
        about: 'O bibliotece',
        areYouSureToDeleteTheSelectedRows: 'Czy na pewno chcesz usunąć wybrane wiersze?',
        areYouSureToDeleteTheSelectedColumns: 'Czy na pewno chcesz usunąć wybrane kolumny?',
        thisActionWillDestroyAnyExistingMergedCellsAreYouSure:
          'Ta czynność zniszczy wszystkie istniejące scalone komórki. Jesteś pewny?',
        thisActionWillClearYourSearchResultsAreYouSure:
          'Akcja spowoduje wyczyszczenie wyników wyszukiwania. Jesteś pewny?',
        thereIsAConflictWithAnotherMergedCell: 'Wystąpił konflikt z inną scaloną komórką',
        invalidMergeProperties: 'Nieprawidłowe komórki do scalenia',
        cellAlreadyMerged: 'Komórka już scalona',
        noCellsSelected: 'Nie wybrano żadnych komórek',
      },
    };

    const id = this.$route.params.id || '';
    if (id) {
      spreadsheetService.get(id).then((data: any) => {
        this.jExcelOptions.data = data.data || [[]];
        this.jExcelOptions.mergeCells = data.merges || [];
        this.spreadSheetExternalData = data.fetchData || {};
        this.jExcelOptions.style = data.styles || [];
        this.jExcelObj = jexcel(this.$refs['spreadsheet'] as any, this.jExcelOptions);
        this.cellsWidth = data.columnWidth || {};
        this.cellsHeight = data.rowHeight || {};
        Object.keys(this.cellsWidth).map((key: any) => {
          this.jExcelObj.setWidth(key, this.cellsWidth[key]);
        });
        Object.keys(this.cellsHeight).map((key: any) => {
          if (this.cellsHeight[key]) {
            this.jExcelObj.setHeight(key, this.cellsHeight[key]);
          }
        });
        if (data.tableMeta && data.tableMeta.moneyCells) {
          this.moneyCells = data.tableMeta.moneyCells;
          this.updateMoneyCells();
        }
        (window as any).jex = this.jExcelObj;
        this.isLoading = false;
      });
    }
  }

  getDataToFetchCount() {
    return Object.keys(this.dataToFetch).length;
  }

  updateExternalCells() {
    if (Object.keys(this.dataToFetch).length > 1) {
      this.needMultipleCellsUpdate = true;
    }
    if (Object.keys(this.dataToFetch).length > 0) {
      return;
    }
    if (this.needMultipleCellsUpdate) {
      this.updateExternalCellsAll();
    } else {
      this.updateExternalCellSingle();
    }
    this.needMultipleCellsUpdate = false;
  }

  updateExternalCellSingle() {
    Object.keys(this.cellsToUpdate).map((cellName: any) => {
      const val = this.jExcelObj.getValue(cellName);
      if (
        val &&
        val[0] === '=' &&
        (val.toLowerCase().indexOf('account') !== -1 ||
          val.toLowerCase().indexOf('saldo') !== -1 ||
          val.toLowerCase().indexOf('konto') !== -1 ||
          val.toLowerCase().indexOf('fn') !== -1 ||
          val.toLowerCase().indexOf('custom') !== -1 ||
          val.toLowerCase().indexOf('zapytanie') !== -1 ||
          val.toLowerCase().indexOf('balance') !== -1)
      ) {
        this.jExcelObj.setValue(cellName, '');
        this.jExcelObj.setValue(cellName, val);
      }
    });
    this.cellsToUpdate = {};
  }

  updateExternalCellsAll() {
    const data = this.jExcelObj.getData();
    data.map((row: any, rowIndex: any) => {
      row.map((col: any, colIndex: any) => {
        if (
          col &&
          col[0] === '=' &&
          (col.toLowerCase().indexOf('account') !== -1 ||
            col.toLowerCase().indexOf('saldo') !== -1 ||
            col.toLowerCase().indexOf('konto') !== -1 ||
            col.toLowerCase().indexOf('fn') !== -1 ||
            col.toLowerCase().indexOf('custom') !== -1 ||
            col.toLowerCase().indexOf('zapytanie') !== -1 ||
            col.toLowerCase().indexOf('balance') !== -1)
        ) {
          const cellName = jexcel.getColumnNameFromId([colIndex, rowIndex]);
          this.jExcelObj.setValue(cellName, '');
          this.jExcelObj.setValue(cellName, col);
        }
      });
    });
    this.cellsToUpdate = {};
  }

  download() {
    const data = JSON.parse(JSON.stringify(this.jExcelObj.getData()));
    data.map((row: any, rowIndex: any) => {
      row.map((col: any, colIndex: any) => {
        if (col && col[0] === '=') {
          data[rowIndex][colIndex] = this.jExcelObj.executeFormula(col, colIndex, rowIndex);
        }
        const cellName = jexcel.getColumnNameFromId([colIndex, rowIndex]);
        if (this.moneyCells[cellName] && !isNaN(parseFloat(data[rowIndex][colIndex]))) {
          data[rowIndex][colIndex] = parseFloat(parseFloat(data[rowIndex][colIndex]).toPrecision(12)).toFixed(2);
        }
      });
    });

    xlsxService.generate(data, {
      merges: this.jExcelObj.getMerge(),
      styles: this.jExcelObj.getStyle(),
      moneyCells: this.moneyCells,
      columnWidth: this.jExcelObj.getWidth(),
      rowHeight: this.cellsHeight,
    });
  }

  undo() {
    this.jExcelObj.undo();
  }

  redo() {
    this.jExcelObj.redo();
  }

  bold() {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      if (styles.indexOf('font-weight: bold') !== -1) {
        cells[cell] = styles.replace('font-weight: bold', 'font-weight: normal');
      } else if (styles.indexOf('font-weight: normal') !== -1) {
        cells[cell] = styles.replace('font-weight: normal', 'font-weight: bold');
      } else {
        cells[cell] = styles + 'font-weight: bold;';
      }
    });
    this.jExcelObj.setStyle(cells);
  }

  italic() {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      if (styles.indexOf('font-style: italic') !== -1) {
        cells[cell] = styles.replace('font-style: italic', 'font-style: normal');
      } else if (styles.indexOf('font-style: normal') !== -1) {
        cells[cell] = styles.replace('font-style: normal', 'font-style: italic');
      } else {
        cells[cell] = styles + 'font-style: italic;';
      }
    });
    this.jExcelObj.setStyle(cells);
  }

  mergeCells() {
    this.jExcelObj.setMerge(this.firstSelectedCell, this.lastSelectedWidth, this.lastSelectedHeight);
  }

  removeMergeCells() {
    this.jExcelObj.removeMerge(this.firstSelectedCell);
  }

  setAlign(align: string) {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      cells[cell] = styles + `text-align: ${align};`;
    });
    this.jExcelObj.setStyle(cells);
  }

  setColor(color: string) {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      cells[cell] = styles + `color: ${color};`;
    });
    this.jExcelObj.setStyle(cells);
  }

  setFontSize(fontSize: string) {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      cells[cell] = styles + `font-size: ${fontSize};`;
    });
    this.jExcelObj.setStyle(cells);
  }

  setBackground(color: string) {
    const cells: any = {};
    this.lastSelectedCells.map((cell: string) => {
      const styles = this.jExcelObj.getStyle(cell);
      cells[cell] = styles + `background-color: ${color};`;
    });
    this.jExcelObj.setStyle(cells);
  }

  save() {
    const id = this.$route.params.id || '';
    if (id) {
      spreadsheetService
        .save(id, {
          data: this.jExcelObj.getData(),
          merges: this.jExcelObj.getMerge(),
          fetchData: { ...this.spreadSheetExternalData },
          styles: this.jExcelObj.getStyle(),
          tableMeta: {
            moneyCells: this.moneyCells,
          },
          columnWidth: this.cellsWidth,
          rowHeight: this.cellsHeight,
        })
        .then(() => {
          EventBus.$emit('show-success', 'Zapisano');
        });
    }
  }

  setMoneyFormat() {
    this.lastSelectedCells.map((cell: string) => {
      const value = this.jExcelObj.getValue(cell);
      this.moneyCells[cell] = 1;
      if (value !== '') {
        this.jExcelObj.setValue(cell, this.jExcelObj.getValue(cell));
      }
    });
  }

  updateMoneyCells() {
    Object.keys(this.moneyCells).map((cell: string) => {
      const value = this.jExcelObj.getValue(cell);
      if (value !== '') {
        this.jExcelObj.setValue(cell, this.jExcelObj.getValue(cell));
      }
    });
  }

  unsetMoneyFormat() {
    this.lastSelectedCells.map((cell: string) => {
      const value = this.jExcelObj.getValue(cell);
      delete this.moneyCells[cell];
      if (value !== '') {
        this.jExcelObj.setValue(cell, this.jExcelObj.getValue(cell));
      }
    });
  }

  moneyFormat(number: any) {
    const parts = parseFloat(parseFloat(number).toPrecision(12))
      .toFixed(2)
      .split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }
}
