import { httpService } from '@/services/HttpService';

class CustomQueryTesterService {
  test(id: any, data: any): Promise<any> {
    return httpService.post(`customQueryTest/${id}`, data, {});
  }
  findQuery(name: any): Promise<any> {
    return httpService.get(`customQueryTest/${name}`, {}, {});
  }
}

export const customQueryTesterService = new CustomQueryTesterService();
